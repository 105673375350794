// import { updateLoadingStatus } from 'store';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFromLocalStorage, setToLocalStorage } from 'store/localStorage';
import { API_URL } from 'config';

export const addOrder = createAsyncThunk('orders/addOrder', async (post, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/orders/add`, {
            method: 'POST',
            body: JSON.stringify(post),
            header: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

const setError = (state, action) => {
    state.status = 'rejected';
    state.error = action.payload;
};

const defaultCurrentOrder = {
    id: 0,
    cart: [],
    date: "",
    customer: null,
    customerId: 0,
    sellerId: 0,
    total: 0,
};

export const MarketSlice = createSlice({
    name: 'market',
    initialState: {
        currentOrder: getFromLocalStorage('currentOrder', defaultCurrentOrder),
        paymentMethods: [],
        currentProduct: null,
        status: '',
        error: null
    },
    reducers: {
        addToCart: (state, action) => {
            var item = action.payload;
            var cart = state.currentOrder.cart;

            for (let i = 0; i < cart.length; i++) {
                if (cart[i].id === item.id) {

                    var newQuantity = parseFloat(item.quantity) + parseFloat(cart[i].quantity);

                    if(newQuantity > item.stock){
                        newQuantity = parseFloat(item.stock)
                    }

                    cart[i].quantity = newQuantity;

                    const newOrder = {
                        ...state.currentOrder,
                        cart: cart
                    }
                    
                    state.currentOrder = {...newOrder}
                    setToLocalStorage('currentOrder', {...newOrder});
                    return;
                }
            }

            cart.push(item);

            const newOrder = {
                ...state.currentOrder,
                cart: cart
            }

            state.currentOrder = {...newOrder}
            setToLocalStorage('currentOrder', {...newOrder});
            
            // setToLocalStorage('cart', state.cart);
        },
        removeFromCart: (state, action) => {
            const itemPayload = action.payload;

            var cart = state.currentOrder.cart;

            cart.map((item, index) => {
                if (item.id === itemPayload.id) {
                    cart.splice(index, 1);
                    
                    const newOrder = {
                        ...state.currentOrder,
                        cart: cart
                    }
        
                    state.currentOrder = {...newOrder}
                    setToLocalStorage('currentOrder', {...newOrder});
                }
            });
        },
        setCurrentOrder: (state, action) => {
            console.log(action.payload)
            state.currentOrder = {...action.payload}
            setToLocalStorage('currentOrder', {...action.payload} );
        },
        resetCurrentOrder: (state) => {
            state.currentOrder = {...defaultCurrentOrder}
            setToLocalStorage('currentOrder', {...defaultCurrentOrder});
        },
        updateMarketCartItem: (state, action) => {
            var item = action.payload;

            var cart = state.currentOrder.cart;

            for (let i = 0; i < cart.length; i++) {
                if (cart[i].id === item.id) {
                    cart[i] = item;
                    break;
                }
            }

            const currentDate = new Date();
            const secondsSinceEpoch = Math.floor(currentDate.getTime() / 1000);
            
            const newOrder = {
                ...state.currentOrder,
                date: secondsSinceEpoch,
                cart: cart
            }

            state.currentOrder = {...newOrder}
            setToLocalStorage('currentOrder', {...newOrder});

            // setToLocalStorage('cart', state.cart);
        },
        updateProductQuantity: (state, action) => {
            var item = action.payload;

            var cart = state.currentOrder.cart;

            for (let i = 0; i < cart.length; i++) {
                if (cart[i].id === item.id) {
                    cart[i].quantity = item.quantity;
                    break;
                }
            }

            const newOrder = {
                ...state.currentOrder,
                cart: cart
            }

            state.currentOrder = {...newOrder}
            setToLocalStorage('currentOrder', {...newOrder});

            // setToLocalStorage('cart', state.cart);
        }
    },
    extraReducers: {
        [addOrder.pending]: (state) => {
            state.status = 'loading';
            state.error = null;
        },
        [addOrder.fulfilled]: (state) => {
            state.status = 'resolved';
            resetCurrentOrder();
        },
        [addOrder.rejected]: setError
    }
});

// Action creators are generated for each case reducer function
export const { addToCart, updateMarketCartItem, removeFromCart, resetCurrentOrder, setCurrentOrder, updateProductQuantity } = MarketSlice.actions;

export default MarketSlice.reducer;
