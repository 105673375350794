// Hook
export const getFromLocalStorage = (key, initialValue) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    if (typeof window === 'undefined') {
        return;
    }

    try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        // if (typeof item === 'string') {
        //     return item;
        // }

        return item ? JSON.parse(item) : initialValue;
    } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
    }
};

export const setToLocalStorage = (key, value) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value() : value;
        // Save to local storage
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
    } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
    }
};

export const removeFromLocalStorage = (key) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    try {
        // Remove from local storage
        if (typeof window !== 'undefined') {
            window.localStorage.removeItem(key);
        }
    } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
    }
};
