import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate, useLocation } from 'react-router';
import useAuth from 'hooks/use-auth';

import AuthGuard from 'utils/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Report = Loadable(lazy(() => import('views/dashboard/Report')));
const Market = Loadable(lazy(() => import('views/market/index')));

const OrdersList = Loadable(lazy(() => import('views/orders/index')));
const OrderItem = Loadable(lazy(() => import('views/orders/single-item')));

// Users
const UsersList = Loadable(lazy(() => import('views/users/list')));
const UserPage = Loadable(lazy(() => import('views/users/single')));

const CustomersList = Loadable(lazy(() => import('views/users/customers/list')));
const CustomerPage = Loadable(lazy(() => import('views/users/customers/single')));
const CustomersNew = Loadable(lazy(() => import('views/users/customers/add')));

const EmployeesList = Loadable(lazy(() => import('views/users/employees/list')));
const EmployeePage = Loadable(lazy(() => import('views/users/customers/single')));
const EmployeeNew = Loadable(lazy(() => import('views/users/customers/add')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

const ProductsList = Loadable(lazy(() => import('views/products/List')));
const ProductsCategories = Loadable(lazy(() => import('views/products/Categories')));
const ProductEdit = Loadable(lazy(() => import('views/products/Edit')));
const AddProduct = Loadable(lazy(() => import('views/products/Add')));

const InvoicesList = Loadable(lazy(() => import('views/invoices/List')));
const AddInvoice = Loadable(lazy(() => import('views/invoices/Add')));
const EditInvoice = Loadable(lazy(() => import('views/invoices/Edit')));
const InvoicesProvidersList = Loadable(lazy(() => import('views/invoices/ProvidersList')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'market',
            element: <Market />
        },
        {
            path: 'report',
            element: <Report />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                },
                // {
                //     path: 'orders/list',
                //     element: <OrdersList />
                // },
                // {
                //     path: 'orders',
                //     children: [
                //         {
                //             path: ':orderId',
                //             element: <OrderItem />
                //         },
                //     ]
                // }
            ]
        },
        {
            path: 'orders/list/',
            element: <OrdersList />
        },
        {
            path: 'orders/list',
            children: [
                {
                    path: ':orderId',
                    element: <OrderItem />
                },
            ]
        },
        {
            path: 'products/',
            element: <ProductsList />
        },
        {
            path: 'products',
            children: [
                {
                    path: '*',
                    element: <ProductsList />
                },
                {
                    path: 'list',
                    element: <ProductsList />
                },
                {
                    path: 'list/:productId',
                    element: <ProductEdit />
                },
                {
                    path: 'list/add',
                    element: <AddProduct />
                },
                {
                    path: 'categories',
                    element: <ProductsCategories />
                }
            ]
        },
        {
            path: 'invoices',
            children: [
                {
                    path: 'list',
                    element: <InvoicesList />
                },
                {
                    path: 'list/:invoiceId',
                    element: <EditInvoice />
                },
                {
                    path: 'add',
                    element: <AddInvoice />
                },
                {
                    path: 'providers',
                    element: <InvoicesProvidersList />
                }
            ]
        },
        {
            path: 'users',
            children: [
                {
                    path: 'list',
                    element: <UsersList />,
                },
                {
                    path: 'list/:userId',
                    element: <UserPage />
                },
                {
                    path: 'employees',
                    children: [
                        {
                            path: 'list',
                            element: <EmployeesList />
                        },
                        {
                            path: 'list/:userId',
                            element: <EmployeePage />
                        },
                        {
                            path: 'list/add',
                            element: <EmployeeNew />
                        },
                    ]
                },
                {
                    path: 'customers/list',
                    element: <CustomersList />
                },
                {
                    path: 'customers/list/add',
                    element: <CustomersNew />
                },
                {
                    path: 'customers/list/:userId',
                    element: <CustomerPage />
                },
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
