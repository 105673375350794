import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        status: false
    },
    reducers: {
        updateLoadingStatus: (state, action) => {
            state.status = action.payload ?? false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { updateLoadingStatus } = loadingSlice.actions;

export default loadingSlice.reducer;
