import { createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from 'config';

export const fetchAllUsers = createAsyncThunk('users/list', async function (params, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/api/v1/users/list`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchUserById = createAsyncThunk('users/list/id', async function (id, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/api/v1/users/list/${id}`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

import { fetchCustomers, updateCustomerAjax, fetchCustomerById } from './customers';
import { fetchEmployees, updateEmployeeAjax, fetchEmployeeById  } from './employee';

export { fetchCustomers, updateCustomerAjax, fetchCustomerById };
export { fetchEmployees, updateEmployeeAjax, fetchEmployeeById };

const setError = (state, action) => {
    state.status = 'rejected';
    state.error = action.payload;
};

export const UsersSlice = createSlice({
    name: 'users',
    initialState: {
        status: '',
        loading: false,
        error: null,
        all: [],
        list: [],
        admins: [],
        employees: [],
        customers: [],
    },
    reducers: {},
    extraReducers: {
        [fetchAllUsers.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.all = action.payload.data;
        },

        [fetchCustomers.pending]: (state) => {
            state.status = 'loading';
            state.error = null;
        },
        [fetchCustomers.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.customers = action.payload.data;
        },
        
        [fetchEmployees.pending]: (state) => {
            state.status = 'loading';
            state.error = null;
        },
        [fetchEmployees.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.employees = action.payload.data;
        },

        [fetchAllUsers.rejected]: setError,
        [fetchEmployees.rejected]: setError,
        [fetchCustomers.rejected]: setError,
        [fetchEmployeeById.rejected]: setError,
        [fetchCustomerById.rejected]: setError,
        [updateEmployeeAjax.rejected]: setError,
        [updateCustomerAjax.rejected]: setError,
    }
});

// Action creators are generated for each case reducer function
// export const { editProduct, defaultCurrentProduct } = ProductsSlice.actions;

export default UsersSlice.reducer;
