// assets
import { IconList, IconListNumbers, IconBuildingStore } from '@tabler/icons';

// constant
const icons = {
    IconList,
    IconListNumbers,
    IconBuildingStore
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

export const ProductPages = {
    id: 'products',
    title: 'Товари',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'products',
            title: 'Каталог товарів',
            type: 'item',
            url: '/products/list',
        },
        {
            id: 'product-list',
            title: 'Каталог товарів',
            type: 'collapse',
            url: '/products/list',
            children: [
                {
                    id: 'product-page',
                    title: 'Товар № ',
                    type: 'item',
                    pattern: '/products/list/(\\d+)',
                }
            ]
        },
        {
            id: 'categories',
            title: 'Категорії товарів',
            type: 'item',
            url: '/products/categories',
            breadcrumbs: true
        }
    ]
};

export const ProductMenuItems = {
    id: 'products',
    title: 'Товари',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'products',
            title: 'Каталог товарів',
            type: 'item',
            url: '/products/list',
            icon: icons.IconBuildingStore,
            breadcrumbs: true
        },
        {
            id: 'categories',
            title: 'Категорії товарів',
            type: 'item',
            url: '/products/categories',
            icon: icons.IconListNumbers
        }
    ]
};

export default ProductMenuItems;
