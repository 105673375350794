import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from 'config';

export const fetchProviders = createAsyncThunk('providers/list', async function (params, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/api/v1/providers/list`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateProviderAjax = createAsyncThunk('providers/update', async (post, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/providers/update`, {
            method: 'POST',
            body: JSON.stringify(post),
            header: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

const setError = (state, action) => {
    state.status = 'rejected';
    state.error = action.payload;
};

export const ProvidersSlice = createSlice({
    name: 'providers',
    initialState: {
        list: []
    },
    reducers: {},
    extraReducers: {
        [fetchProviders.pending]: (state) => {
            // console.log(state.loading);
            // dispatch(updateLoadingStatus(true))
            // console.log(store);
            state.status = 'loading';
            state.error = null;
        },
        [fetchProviders.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.list = action.payload.data;
        },

        [fetchProviders.rejected]: setError
    }
});

// Action creators are generated for each case reducer function
// export const { editProduct, defaultCurrentProduct } = ProductsSlice.actions;

export default ProvidersSlice.reducer;
