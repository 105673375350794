import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';
// import { goodsApi } from './products';
// import { goodsApi } from './products/productsApi';

// export * from './products/productsApi';
// export * from './products/productsSlice';
export * from './localStorage';
export * from './app';
export * from './cashdesk';
export * from './products';
// export * from './cart';
export * from './market';
export * from './orders';
export * from './invoices';
export * from './categories';
export * from './providers';
export * from './users';
// ==============================|| REDUX - MAIN STORE ||============================== //

export const store = configureStore({
    reducer: rootReducer
    // middleware: (getDefaultMiddlware) => getDefaultMiddlware().concat(goodsApi.middleware)
});
// const persister = 'Free';

// export { store };
