import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { m } from 'framer-motion';
import { API_URL } from 'config';

export const updateCategoryAjax = createAsyncThunk('categories/update', async (post, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/categories/update`, {
            method: 'POST',
            body: JSON.stringify(post),
            header: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

export const fetchCategories = createAsyncThunk('categories/list', async function (params, { rejectWithValue }) {

    var onlyParent = '';

    if(params){
        const onlyParentParam = params.onlyParent ?? false;
        onlyParent = onlyParentParam ? '1' : '0';
    }

    try {
        const response = await fetch(`${API_URL}/api/v1/categories/list?only_parent=${onlyParent}`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});


const setError = (state, action) => {
    state.status = 'rejected';
    state.error = action.payload;
    state.loading = false;
};

export const CategoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        list: [],
        listParents: [],
        loading: false,
        status: '',
        error: null
    },
    reducers: {
        updateCategory: (state, action) => {
            console.log(state)
            console.log(action)
        },
        // defaultCurrentProduct: (state, action) => {
        //     state.currentProduct = {
        //         id: 0,
        //         barcode: '',
        //         name: '',
        //         categoryId: '',
        //         category: {
        //             id: 0,
        //             name: ''
        //         }
        //     };
        // }
    },
    extraReducers: {
        [fetchCategories.pending]: (state) => {
            state.status = 'loading';
            state.loading = true;
            state.error = null;
        },
        [fetchCategories.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.list = action.payload.data
        },
        [fetchCategories.rejected]: setError,
    }
});

// Action creators are generated for each case reducer function
export const { updateCategory } = CategoriesSlice.actions;

export default CategoriesSlice.reducer;
