import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Redirect } from 'react-router-dom';
import { checkSession } from 'store/sessions';

//-----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const dispatch = useDispatch();

    const isAuth = useSelector((state) => {
        // state.sessions.user
        return state.sessions.isAuth;
    });

    const sessionId = useSelector((state) => {
        // state.sessions.user
        return state.sessions.sessionId;
    });

    useEffect(() => {
        if (sessionId) {
            dispatch(checkSession(sessionId));
        }
    }, [dispatch]);

    if (!sessionId) {
        return <Navigate to="/login" />;
    }

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
