// assets
import { IconShoppingCart, IconCheckupList } from '@tabler/icons';

// constant
const icons = { IconShoppingCart, IconCheckupList };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

export const MarketPages = {
    id: 'market',
    title: 'Продажі',
    type: 'group',
    children: [
        {
            id: 'market',
            title: 'Продаж',
            type: 'item',
            url: '/market',
            breadcrumbs: false
        },
        {
            id: 'orders',
            title: 'Журнал продажів',
            type: 'item',
            url: '/orders/list'
        },
        {
            id: 'order-list',
            title: 'Журнал продажів',
            // type: 'item',
            url: '/orders/list',
            type: 'collapse',
            children: [
                {
                    id: 'order-page',
                    title: 'Замовлення № ',
                    type: 'item',
                    pattern: '/orders/list/(\\d+)',
                }
            ]
        },
    ]
};

export const MarketMenuItems = {
    id: 'market',
    title: 'Продажі',
    type: 'group',
    children: [
        {
            id: 'market',
            title: 'Продаж',
            type: 'item',
            url: '/market',
            icon: icons.IconShoppingCart,
            breadcrumbs: false
        },
        {
            id: 'orders',
            title: 'Журнал продажів',
            type: 'item',
            url: '/orders/list',
            icon: icons.IconCheckupList,
            breadcrumbs: true,
        },
    ]
};

export default MarketMenuItems;
