// assets
import { IconDashboard, IconShoppingCart, IconCheckupList } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconShoppingCart, IconCheckupList };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

export const DashboardPages = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        // {
        //     id: 'default',
        //     title: 'Dashboard',
        //     type: 'item',
        //     url: '/dashboard/default',
        // },
        {
            id: 'report',
            title: 'Зведений звіт',
            type: 'item',
            url: '/report',
        },
    ]
};

export const DashboardMenuItems = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        // {
        //     id: 'default',
        //     title: 'Dashboard',
        //     type: 'item',
        //     url: '/dashboard/default',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false
        // },
        {
            id: 'report',
            title: 'Зведений звіт',
            type: 'item',
            url: '/report',
            icon: icons.IconDashboard,
            breadcrumbs: true
        },
    ]
};

export default DashboardMenuItems;
