// import { updateLoadingStatus } from 'store';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { m } from 'framer-motion';
import { API_URL } from 'config';

export const updateProduct = createAsyncThunk('products/list/id/update', async (post, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/products/list/${post.id}`, {
            method: 'PUT',
            body: JSON.stringify(post),
            header: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

export const fetchProductById = createAsyncThunk('products/list/id', async function (id, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/api/v1/products/list/${id}`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchTypes = createAsyncThunk('products/types', async function (params, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/api/v1/products/types`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchProducts = createAsyncThunk('products/list', async function (params, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/api/v1/products/list`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const searchProducts = createAsyncThunk('products/search', async function (data, { rejectWithValue }) {
    const s = data.s;
    const dataParams = data.params;
    var dataParamsString = '';

    if (dataParams) {
        dataParamsString = new URLSearchParams(dataParams).toString();
    }

    try {
        const response = await fetch(`${API_URL}/api/v1/products/search/` + s + '?' + dataParamsString);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const setError = (state, action) => {
    state.status = 'rejected';
    state.error = action.payload;
    state.loading = false;
};

export const ProductsSlice = createSlice({
    name: 'products',
    initialState: {
        cart: [],
        list: [],
        loading: false,
        searchProductsList: [],
        categories: [],
        categoriesParents: [],
        types: [],
        paymentMethods: [],
        value: 0,
        currentProduct: {
            id: 0,
            sku: '',
            barcode: '',
            name: '',
            categoryId: '',
            category: {
                id: 0,
                name: ''
            }
        },
        status: ''
    },
    reducers: {
        updateCurrentProduct: (state, action) => {
            state.currentProduct = action.payload;
        },
        defaultCurrentProduct: (state, action) => {
            state.currentProduct = {
                id: 0,
                sku: '',
                barcode: '',
                name: '',
                categoryId: '',
                category: {
                    id: 0,
                    name: ''
                }
            };
        }
    },
    extraReducers: {
        [fetchProducts.pending]: (state) => {
            state.status = 'loading';
            state.loading = true;
            state.error = null;
        },
        [fetchProductById.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.currentProduct = action.payload.data;
        },
        [fetchProducts.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.list = action.payload.data;
            state.loading = false;
        },
        [fetchTypes.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.types = action.payload.types;
        },
        [fetchProductById.rejected]: setError,
        [fetchTypes.rejected]: setError,
        [fetchProducts.rejected]: setError,
        [searchProducts.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.searchProductsList = action.payload.data;
        }
    }
});

// Action creators are generated for each case reducer function
export const { defaultCurrentProduct } = ProductsSlice.actions;

export default ProductsSlice.reducer;
