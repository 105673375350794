// import pages from './pages';
// import utilities from './utilities';
// import other from './other';

import { DashboardPages, DashboardMenuItems } from './dashboard';
import { ProductPages, ProductMenuItems } from './products';
import { InvoicesPages, InvoicesMenuItems } from './invoices';
import { UsersPages, UsersMenuItems } from './users';
import { SystemPages, SystemMenuItems } from './system';
import { MarketPages, MarketMenuItems } from './market';

// ==============================|| MENU ITEMS ||============================== //

export const pageItems = {
    items: [DashboardPages, MarketPages, ProductPages, InvoicesPages, UsersPages, SystemPages]
};

const menuItems = {
    items: [DashboardMenuItems, MarketMenuItems, ProductMenuItems, InvoicesMenuItems, UsersMenuItems, SystemMenuItems]
};

export default menuItems;
