import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from 'config';

export const fetchEmployees = createAsyncThunk('users/employees/list', async function (params, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/api/v1/users/employees/list`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchEmployeeById = createAsyncThunk('users/employees/list/id', async function (id, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/api/v1/users/employees/list/${id}`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});


export const updateEmployeeAjax = createAsyncThunk('users/employees/list/update', async (post, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/users/employees/list/update/`, {
            method: 'POST',
            body: JSON.stringify(post),
            header: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});