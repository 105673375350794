// assets
import { IconList, IconPlus, IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconList,
    IconPlus,
    IconUsers
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

export const InvoicesPages = {
    id: 'invoices',
    title: 'Накладні',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'invoices-add',
            title: 'Додати нову накладну',
            type: 'item',
            url: '/invoices/add'
        },
        {
            id: 'invoices-list',
            title: 'Список накладних',
            url: '/invoices/list',
            type: 'item',
        },
        {
            id: 'invoices-list',
            title: 'Список накладних',
            // type: 'item',
            url: '/invoices/list',
            type: 'collapse',
            children: [
                {
                    id: 'invoice-page',
                    title: 'Накладна № ',
                    type: 'item',
                    pattern: '/invoices/list/(\\d+)',
                }
            ]
        },
        {
            id: 'providers',
            title: 'Постачальники',
            type: 'item',
            url: '/invoices/providers'
        },
    ]
};

export const InvoicesMenuItems = {
    id: 'invoices',
    title: 'Накладні',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'invoices-add',
            title: 'Додати нову накладну',
            type: 'item',
            url: '/invoices/add',
            icon: icons.IconPlus
        },
        {
            id: 'invoices-list',
            title: 'Список накладних',
            type: 'item',
            url: '/invoices/list',
            icon: icons.IconList,
        },
        {
            id: 'providers',
            title: 'Постачальники',
            type: 'item',
            url: '/invoices/providers',
            icon: icons.IconUsers
        }
    ]
};

export default InvoicesMenuItems;
