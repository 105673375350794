import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from 'config';
import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from 'store/localStorage';

export const checkSession = createAsyncThunk('sessions/check', async (sessionId, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/sessions/check/` + sessionId);
        const data = await response.json();
        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

export const killSession = createAsyncThunk('sessions/kill', async (sessionId, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/sessions/kill/` + sessionId);
        const data = await response.json();
        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

export const getSession = createAsyncThunk('sessions/get', async (post, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/sessions/get`, {
            method: 'POST',
            body: JSON.stringify(post),
            header: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

const setError = (state, action) => {
    state.status = 'rejected';
    state.error = action.payload;
};

export const sessionsSlice = createSlice({
    name: 'sessions',
    initialState: {
        isAuth: false,
        sessionId: getFromLocalStorage('sessionId', ''),
        status: '',
        error: null,
        session: getFromLocalStorage('session', null),
        user: getFromLocalStorage('user', null)
    },
    reducers: {},
    extraReducers: {
        [checkSession.pending]: (state) => {
            state.status = 'loading';
            state.error = null;
        },
        [checkSession.fulfilled]: (state, action) => {
            if (!action.payload.ok) {
                state.status = 'rejected';
                state.error = action.payload.error;
                return;
            }
            const session = action.payload.session;

            state.status = 'resolved';
            state.sessionId = session.sessionId;
            state.user = session.user;
            state.session = session;
            state.isAuth = true;
        },

        [getSession.pending]: (state) => {
            state.status = 'loading';
            state.error = null;
        },
        [getSession.fulfilled]: (state, action) => {
            if (!action.payload.ok) {
                state.status = 'rejected';
                state.error = action.payload.error;
                return;
            }

            const session = action.payload.session;

            state.status = 'resolved';

            state.sessionId = session.sessionId;
            state.user = session.user;
            state.session = session;
            state.isAuth = true;
            setToLocalStorage('user', session.user);
            setToLocalStorage('session', session);

            setToLocalStorage('sessionId', session.sessionId);
            // state.sessionId = action.payload.data;
        },

        [killSession.fulfilled]: (state, action) => {
            if (!action.payload.ok) {
                state.status = 'rejected';
                state.error = action.payload.error;
                return;
            }

            // const session = action.payload.session;

            state.status = 'resolved';

            state.sessionId = '';
            state.user = null;
            state.session = null;
            state.isAuth = false;

            removeFromLocalStorage('sessionId');
            removeFromLocalStorage('session');
            removeFromLocalStorage('user');
            // state.sessionId = action.payload.data;
        },

        [getSession.rejected]: setError,
        [checkSession.rejected]: setError,
        [killSession.rejected]: setError
    }
});

// Action creators are generated for each case reducer function
// export const { updateLoadingStatus, setNavigation, setPage } = appSlice.actions;

export default sessionsSlice.reducer;
