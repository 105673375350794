// assets
import { IconShieldChevron, IconUserCircle, IconAlien, IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconShieldChevron,
    IconUserCircle,
    IconAlien,
    IconUsers
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

export const UsersPages = {
    id: 'users',
    title: 'Користувачі',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Список користувачів',
            type: 'item',
            url: '/users/list',
            icon: icons.IconUsers,
            breadcrumbs: true
        },
        {
            id: 'user-page',
            title: 'Користувачі',
            url: '/users/list',
            type: 'collapse',
            children: [
                {
                    id: 'order-page',
                    title: 'Користувач № ',
                    type: 'item',
                    pattern: '/users/list/(\\d+)',
                }
            ]
        },

        {
            id: 'employees-list',
            title: 'Працівники',
            type: 'item',
            url: '/users/employees/list',
            breadcrumbs: true,
        },
        {
            id: 'employee-page',
            title: 'Працівники',
            url: '/users/employees/list',
            type: 'collapse',
            children: [
                {
                    id: 'order-page',
                    title: 'Користувач № ',
                    type: 'item',
                    pattern: '/users/employees/list/(\\d+)',
                }
            ]
        },
        {
            id: 'customers-list',
            title: 'Покупці',
            type: 'item',
            url: '/users/customers/list',
            breadcrumbs: true,
        },
        {
            id: 'customer-page',
            title: 'Покупці',
            url: '/users/customers/list',
            type: 'collapse',
            children: [
                {
                    id: 'order-page',
                    title: 'Користувач № ',
                    type: 'item',
                    pattern: '/users/customers/list/(\\d+)',
                }
            ]
        },
        {
            id: 'customers-groups',
            title: 'Групи покупців',
            type: 'item',
            url: '/users/customers/groups',
            icon: icons.IconAlien
        }
    ]
};


export const UsersMenuItems = {
    id: 'users',
    title: 'Користувачі',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Список користувачів',
            type: 'item',
            url: '/users/list',
            icon: icons.IconUsers,
            breadcrumbs: true,
            permission: 1,
        },
        {
            id: 'employees-list',
            title: 'Працівники',
            type: 'item',
            url: '/users/employees/list',
            icon: icons.IconShieldChevron,
        },
        {
            id: 'customers-list',
            title: 'Покупці',
            type: 'item',
            url: '/users/customers/list',
            icon: icons.IconUserCircle
        },
        {
            id: 'customers-groups',
            title: 'Групи покупців',
            type: 'item',
            url: '/users/customers/groups',
            icon: icons.IconAlien
        }
    ]
};

export default UsersMenuItems;
