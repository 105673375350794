import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import app from './app';
import sessions from './sessions';
import cashdesk from './cashdesk';
// import cart from './cart';
import orders from './orders/index.js';
import products from './products';
import loading from './loading';
import invoices from './invoices';
import providers from './providers';
import categories from './categories';
import users from './users';
import market from './market';
// export * from './products';
// import { goodsApi } from './products/productsApi';
// import todoReducer from './products/productsSlice';
// ==============================|| COMBINE REDUCER ||============================== //

const rootReducer = combineReducers({
    app: app,
    sessions: sessions,
    cashdesk: cashdesk,
    customization: customizationReducer,
    products: products,
    loading: loading,
    // cart: cart,
    orders: orders,
    invoices: invoices,
    providers: providers,
    categories: categories,
    users: users,
    market: market,
    // [goodsApi.reducerPath]: goodsApi.reducer
});

export default rootReducer;
