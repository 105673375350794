export const API_URL = 'https://dambo-backend.geo-fast.com.ua';
// export const API_URL = 'http://localhost:8888';

const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    theme: 'dark',
    defaultPath: '/dashboard/default',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12
};

export default config;
