import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from 'config';
import { getFromLocalStorage, setToLocalStorage } from 'store/localStorage';

export const fetchInvoices = createAsyncThunk('invoices/list', async (_, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/invoices/list`);
        const data = await response.json();

        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

export const fetchInvoiceById = createAsyncThunk('invoices/list/id', async (id, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/invoices/list/${id}`);
        const data = await response.json();

        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

export const fetchStatuses = createAsyncThunk('invoices/statuses/list', async (_, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/invoices/statuses/types`);
        const data = await response.json();

        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

export const addInvoice = createAsyncThunk('invoices/addInvoice', async (post, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/invoices/add`, {
            method: 'POST',
            body: JSON.stringify(post),
            header: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

export const updateInvoice = createAsyncThunk('invoices/updateInvoice', async (post, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/invoices/update`, {
            method: 'POST',
            body: JSON.stringify(post),
            header: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

const setError = (state, action) => {
    state.status = 'rejected';
    state.error = action.payload;
};

export const defaultCurrentInvoice = {
    id: 0,

    userId: "",
    providerId: "",
    notes: "",

    paidStatus: false,
    statusId: '',
    status: '',

    products: [],

    percentage: 0.00,
    summa: 0.00,
    paymentMethodId: 0,

    dateCreated: null,
    datePaid: null,
    dateDelivery: null
};

export const invoicesSlice = createSlice({
    name: 'invoices',
    initialState: {
        list: [],
        // cart: getFromLocalStorage('invoiceCart', []),
        status: '',
        loading: false,
        statusesList: [],
        error: '',
        newInvoice: getFromLocalStorage('newInvoice', defaultCurrentInvoice),
        currentInvoice: null,
        defaultCurrentInvoice: defaultCurrentInvoice,
        // currentInvoice: {
        //     ...defaultCurrentInvoice,
        //     products: getFromLocalStorage('invoiceCart', [])
        // }
    },
    reducers: {

        resetNewInvoice: (state) => {
            state.newInvoice = {...defaultCurrentInvoice};
            setToLocalStorage('newInvoice', {...defaultCurrentInvoice} );
        },

        addProductToInvoice: (state, action) => {

            var item = action.payload;
            const products = state.newInvoice.products 
            
            if(products.length > 0){
                for (let i = 0; i < products.length; i++) {

                    if (products[i].id === item.id) {

                        products[i].priceOpt = parseFloat(item.priceOpt);
                        products[i].quantity = parseFloat(item.quantity) + parseFloat(products[i].quantity);
                        products[i].summa = parseFloat(item.summa);

                        state.newInvoice = {
                            ...state.newInvoice,
                            products: products
                        }

                        setToLocalStorage('newInvoice', state.newInvoice);
                        return;
                    }
                }
            }

            state.newInvoice.products.push(action.payload);
            setToLocalStorage('newInvoice', state.newInvoice);
        },

        removeProductFromInvoice: (state, action) => {
            
            const itemPayload = action.payload;
            const products = state.newInvoice.products;

            if(products.length <= 0){
                return;
            }

            products.map((item, index) => {
                if (item.id === itemPayload.id) {
                    products.splice(index, 1);

                    state.newInvoice = {
                        ...state.newInvoice,
                        products: products
                    }

                    setToLocalStorage('newInvoice', state.newInvoice);
                    return;
                }
            });
        },

        updateNewInvoice: (state, action) => {
            state.newInvoice = action.payload;
            setToLocalStorage('newInvoice', state.newInvoice);
        },

        updateInvoiceProduct: (state, action) => {
            var item = action.payload;

            const products = state.newInvoice.products;

            if(products.length <= 0){
                return;
            }

            for (let i = 0; i < products.length; i++) {
                if (products[i].id === item.id) {
                    products[i] = item;
                    break;
                }
            }

            state.newInvoice = { ...state.newInvoice, products: products }
            setToLocalStorage('newInvoice', state.newInvoice);
        },
    },
    extraReducers: {
        [fetchInvoices.pending]: (state) => {
            state.status = 'loading';
            state.error = null;
            state.loading = true;
        },
        [fetchInvoices.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.list = action.payload.data;
            state.loading = false;
        },
        [fetchInvoices.rejected]: setError,

        [addInvoice.pending]: (state) => {
            state.status = 'loading';
            state.error = null;
        },
        [addInvoice.fulfilled]: (state) => {
            state.status = 'resolved';
        },
        [addInvoice.rejected]: setError,

        [updateInvoice.pending]: (state) => {
            state.status = 'loading';
            state.error = null;
        },
        [updateInvoice.fulfilled]: (state) => {
            state.status = 'resolved';
        },
        [updateInvoice.rejected]: setError,

        [fetchStatuses.pending]: (state) => {
            state.status = 'loading';
            state.error = null;
        },
        [fetchStatuses.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.statusesList = action.payload.data;
        },
        [fetchStatuses.rejected]: setError,

        [fetchInvoiceById.pending]: (state) => {
            state.status = 'loading';
            state.error = null;
        },
        [fetchInvoiceById.fulfilled]: (state, action) => {
            state.status = 'resolved';
        },
        [fetchInvoiceById.rejected]: setError
    }
});

// Action creators are generated for each case reducer function
export const {
    updateNewInvoice,
    addProductToInvoice,
    updateInvoiceProduct,
    removeProductFromInvoice,
    resetNewInvoice,
} = invoicesSlice.actions;

export default invoicesSlice.reducer;
