import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from 'config';

export const getBalance = createAsyncThunk('cashdesk/balance', async (_, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/cashdesk/balance`);
        const data = await response.json();

        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

const setError = (state, action) => {
    state.status = 'rejected';
    state.error = action.payload;
};

export const cashdeskSlice = createSlice({
    name: 'cashdesk',
    initialState: {
        balance: 0,
        status: '',
        error: ""
    },
    reducers: {},
    extraReducers: {
        [getBalance.pending]: (state) => {
            state.status = 'loading';
            state.error = "";
        },
        [getBalance.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.balance = action.payload.data;
        },
        [getBalance.rejected]: setError
    }
});

// Action creators are generated for each case reducer function
// export const { updateLoadingStatus, setNavigation, setPage } = appSlice.actions;

export default cashdeskSlice.reducer;
