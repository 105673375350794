// import { updateLoadingStatus } from 'store';
// import { useState, useEffect } from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from 'config';

export const fetchOrders = createAsyncThunk('orders/list', async (_, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/orders/list`);
        const data = await response.json();

        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

export const fetchOrdersByRange = createAsyncThunk('orders/list/datef/datet', async ({ dateFrom = "", dateTo = "" }, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/orders/list/${dateFrom}/${dateTo}`);
        const data = await response.json();

        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

export const fetchOrdersStatistics = createAsyncThunk('orders/statistics', async ({ dateFrom = "", dateTo = "" }, { rejectWithValue }) => {
    try {
        const response = await fetch(`${API_URL}/api/v1/orders/statistics/${dateFrom}/${dateTo}`);
        const data = await response.json();

        return data;
    } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
    }
});

export const fetchOrderById = createAsyncThunk('orders/list/id', async function (id, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/api/v1/orders/list/${id}`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const setError = (state, action) => {
    state.status = 'rejected';
    state.error = action.payload;
};

export const OrdersSlice = createSlice({
    name: 'orders',
    initialState: {
        list: [],
        paymentMethods: [],
        currentProduct: null,
        status: '',
        error: null,
        loading: false,
    },
    reducers: {},
    extraReducers: {
        [fetchOrders.pending]: (state) => {
            // console.log(state.loading);
            // dispatch(updateLoadingStatus(true))
            // console.log(store);
            state.status = 'loading';
            state.loading = true;
            state.error = null;
        },
        [fetchOrders.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.list = action.payload.data;
            state.loading = false;
        },
        [fetchOrders.rejected]: setError,
        [fetchOrdersStatistics.pending]: (state) => {
            // console.log(state.loading);
            // dispatch(updateLoadingStatus(true))
            // console.log(store);
            state.status = 'loading';
            state.loading = true;
            state.error = null;
        },
        [fetchOrdersStatistics.fulfilled]: (state, action) => {
            state.status = 'resolved';
            state.list = action.payload.data;
            state.loading = false;
        },
        [fetchOrdersStatistics.rejected]: setError,
    }
});

// Action creators are generated for each case reducer function
// export const { addToCart, updateProductQuantity, removeFromCart } = CartSlice.actions;

export default OrdersSlice.reducer;
