// assets
import { IconSettings, IconHelp } from '@tabler/icons';

// constant
const icons = {
    IconSettings,
    IconHelp
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

export const SystemPages = {
    id: 'system',
    title: 'Система',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Налаштування',
            type: 'item',
            url: '/settings',
            breadcrumbs: true,
        },
        {
            id: 'doc',
            title: 'Документація',
            type: 'item',
            url: '/doc',
            breadcrumbs: true,
        }
    ]
};

export const SystemMenuItems = {
    id: 'system',
    title: 'Система',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Налаштування',
            type: 'item',
            url: '/settings',
            icon: icons.IconSettings
        },
        {
            id: 'doc',
            title: 'Документація',
            type: 'item',
            url: '/doc',
            icon: icons.IconHelp
        }
    ]
};

export default SystemMenuItems;
